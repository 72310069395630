var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var wW,xW,Sla,zW,Tla,Ula,Vla,AW,yW;$CLJS.vW=function(a,b){return $CLJS.Sb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Id(d):null},null,b)};wW=function(a,b){$CLJS.J(a,0,null);$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);b=$CLJS.OH(b,$CLJS.OI)?$CLJS.JV:$CLJS.OH(b,$CLJS.XK)?$CLJS.EV:$CLJS.OH(b,$CLJS.SJ)?$CLJS.HV:null;return $CLJS.m(b)?$CLJS.nV(b,a):!0};
xW=function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);a=$CLJS.fj(function(d){return $CLJS.OH($CLJS.BL(d),$CLJS.lJ)},a);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,!1);b=$CLJS.M.h(b,!0);if($CLJS.ki.h($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.BL($CLJS.C(a));return $CLJS.Xf(function(d){$CLJS.J(d,0,null);$CLJS.J(d,1,null);$CLJS.J(d,2,null);
var e=$CLJS.J(d,3,null);return $CLJS.m(wW(d,c))?null:["Cannot add a ",$CLJS.p.g(e)," interval to a ",$CLJS.p.g(c)," expression"].join("")},b)};
Sla=function(a){return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.ev,[$CLJS.p.g(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.wL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.At,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,yW],
null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.HL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.At,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,yW],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.gv,function(b){b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.sl);return["Invalid ",$CLJS.p.g(a)," clause: ",$CLJS.p.g(xW(b))].join("")}],null),$CLJS.$f(xW)],null)],null)};
zW=function(a){var b=$CLJS.T,c=Sla(a);a=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.wl,new $CLJS.k(null,1,[$CLJS.ev,[$CLJS.p.g(a)," clause with numeric args"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.wL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.At,new $CLJS.k(null,1,[$CLJS.sk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.QL],null)],null)],null)],
null);return new $CLJS.S(null,3,5,b,[$CLJS.Dk,c,a],null)};Tla=function(a){return $CLJS.Ve($CLJS.kV,$CLJS.rg.h(function(b){var c=$CLJS.BL(b),d=$CLJS.OH(c,$CLJS.KL);b=d?$CLJS.nV($CLJS.BV,b):d;return $CLJS.m(b)?$CLJS.Hl:c},a))};Ula=function(a){a=$CLJS.vW(function(b){return!$CLJS.OH(b,$CLJS.lJ)},$CLJS.rg.h($CLJS.BL,a));return $CLJS.OH(a,$CLJS.KL)?$CLJS.Ol:a};Vla=function(a){return $CLJS.m($CLJS.Xf(function(b){return $CLJS.OH($CLJS.BL(b),$CLJS.lJ)},a))?Ula(a):Tla(a)};
AW=new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.BW=new $CLJS.N("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);yW=new $CLJS.N("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.jL(new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Dt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.HL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.N(null,"intervals","intervals",2096054013),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,yW],
null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.ev,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=$CLJS.BL(a);return $CLJS.Wf(function(d){return wW(d,c)},b)}],null)],null));
$CLJS.jL(AW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.At,new $CLJS.k(null,1,[$CLJS.sk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.QL],null)],null)],null));$CLJS.sV($CLJS.om,zW($CLJS.om));$CLJS.sV($CLJS.yu,zW($CLJS.yu));$CLJS.yV($CLJS.pm,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ml,AW],null)]));$CLJS.yV($CLJS.PK,$CLJS.G([$CLJS.yu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ml,AW],null)]));
for(var CW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.om,$CLJS.yu,$CLJS.pm],null)),DW=null,EW=0,FW=0;;)if(FW<EW){var Wla=DW.$(null,FW);$CLJS.lV(Wla,$CLJS.BW);FW+=1}else{var GW=$CLJS.A(CW);if(GW){var HW=GW;if($CLJS.re(HW)){var IW=$CLJS.$c(HW),Xla=$CLJS.ad(HW),Yla=IW,Zla=$CLJS.E(IW);CW=Xla;DW=Yla;EW=Zla}else{var $la=$CLJS.C(HW);$CLJS.lV($la,$CLJS.BW);CW=$CLJS.D(HW);DW=null;EW=0}FW=0}else break}$CLJS.AL.o(null,$CLJS.BW,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);return Vla(a)});
$CLJS.wV($CLJS.tI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.QL],null)],null)]));$CLJS.lV($CLJS.tI,$CLJS.NL);
for(var JW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RK,$CLJS.ZH,$CLJS.oJ],null)),KW=null,LW=0,MW=0;;)if(MW<LW){var ama=KW.$(null,MW);$CLJS.wV(ama,$CLJS.G([$CLJS.yu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.QL],null)],null)]));MW+=1}else{var NW=$CLJS.A(JW);if(NW){var OW=NW;if($CLJS.re(OW)){var PW=$CLJS.$c(OW),bma=$CLJS.ad(OW),cma=PW,dma=$CLJS.E(PW);JW=bma;KW=cma;LW=dma}else{var ema=$CLJS.C(OW);$CLJS.wV(ema,$CLJS.G([$CLJS.yu,$CLJS.KK,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.QL],null)],null)]));JW=$CLJS.D(OW);KW=null;LW=0}MW=0}else break}
for(var QW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jI,$CLJS.GJ,$CLJS.VJ],null)),RW=null,SW=0,TW=0;;)if(TW<SW){var fma=RW.$(null,TW);$CLJS.wV(fma,$CLJS.G([$CLJS.yu,$CLJS.kl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.QL],null)],null)]));TW+=1}else{var UW=$CLJS.A(QW);if(UW){var VW=UW;if($CLJS.re(VW)){var WW=$CLJS.$c(VW),gma=$CLJS.ad(VW),hma=WW,ima=$CLJS.E(WW);QW=gma;RW=hma;SW=ima}else{var jma=$CLJS.C(VW);$CLJS.wV(jma,$CLJS.G([$CLJS.yu,$CLJS.kl,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.QL],null)],null)]));QW=$CLJS.D(VW);RW=null;SW=0}TW=0}else break}$CLJS.wV($CLJS.mI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.QL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.QL],null)],null)]));
$CLJS.AL.o(null,$CLJS.mI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.OH($CLJS.BL(b),$CLJS.kl)&&$CLJS.OH($CLJS.BL(a),$CLJS.kl)?$CLJS.kl:$CLJS.KK});