var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var o2,p2,r2,s2;$CLJS.m2=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.N?a.S:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.n2=function(a,b){a=$CLJS.m2(a);b=$CLJS.m(b)?b:$CLJS.xz;return 0===a?$CLJS.F.h(b,$CLJS.xz)?$CLJS.VH("Today"):$CLJS.UH("This {0}",$CLJS.G([$CLJS.F1.g(b)])):$CLJS.F.h(a,1)?$CLJS.F.h(b,$CLJS.xz)?$CLJS.VH("Tomorrow"):$CLJS.UH("Next {0}",$CLJS.G([$CLJS.F1.g(b)])):$CLJS.F.h(a,-1)?$CLJS.F.h(b,$CLJS.xz)?$CLJS.VH("Yesterday"):$CLJS.UH("Previous {0}",$CLJS.G([$CLJS.F1.g(b)])):0>a?$CLJS.UH("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.F1.h(Math.abs(a),b)])):0<a?$CLJS.UH("Next {0} {1}",$CLJS.G([a,$CLJS.F1.h(a,
b)])):null};o2=new $CLJS.N("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);p2=new $CLJS.N("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);$CLJS.q2=new $CLJS.N(null,"operators","operators",-2064102509);r2=new $CLJS.N("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);s2=new $CLJS.N("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var t2=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lj,$CLJS.Dk],null)),u2=null,v2=0,w2=0;;)if(w2<v2){var lpa=u2.$(null,w2);$CLJS.lV(lpa,r2);w2+=1}else{var x2=$CLJS.A(t2);if(x2){var y2=x2;if($CLJS.re(y2)){var z2=$CLJS.$c(y2),mpa=$CLJS.ad(y2),npa=z2,opa=$CLJS.E(z2);t2=mpa;u2=npa;v2=opa}else{var ppa=$CLJS.C(y2);$CLJS.lV(ppa,r2);t2=$CLJS.D(y2);u2=null;v2=0}w2=0}else break}
for(var A2=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,$CLJS.CK],null)),B2=null,C2=0,D2=0;;)if(D2<C2){var qpa=B2.$(null,D2);$CLJS.lV(qpa,p2);D2+=1}else{var E2=$CLJS.A(A2);if(E2){var F2=E2;if($CLJS.re(F2)){var G2=$CLJS.$c(F2),rpa=$CLJS.ad(F2),spa=G2,tpa=$CLJS.E(G2);A2=rpa;B2=spa;C2=tpa}else{var upa=$CLJS.C(F2);$CLJS.lV(upa,p2);A2=$CLJS.D(F2);B2=null;C2=0}D2=0}else break}
for(var H2=$CLJS.A(new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.km,$CLJS.wm,$CLJS.mm,$CLJS.vm,$CLJS.uJ,$CLJS.mK,$CLJS.KJ,$CLJS.vJ],null)),I2=null,J2=0,K2=0;;)if(K2<J2){var vpa=I2.$(null,K2);$CLJS.lV(vpa,s2);K2+=1}else{var L2=$CLJS.A(H2);if(L2){var M2=L2;if($CLJS.re(M2)){var N2=$CLJS.$c(M2),wpa=$CLJS.ad(M2),xpa=N2,ypa=$CLJS.E(N2);H2=wpa;I2=xpa;J2=ypa}else{var zpa=$CLJS.C(M2);$CLJS.lV(zpa,s2);H2=$CLJS.D(M2);I2=null;J2=0}K2=0}else break}
for(var O2=$CLJS.A(new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.xI,$CLJS.nJ,$CLJS.KI,$CLJS.$I,$CLJS.Vk],null)),P2=null,Q2=0,R2=0;;)if(R2<Q2){var Apa=P2.$(null,R2);$CLJS.lV(Apa,o2);R2+=1}else{var S2=$CLJS.A(O2);if(S2){var T2=S2;if($CLJS.re(T2)){var U2=$CLJS.$c(T2),Bpa=$CLJS.ad(T2),Cpa=U2,Dpa=$CLJS.E(U2);O2=Bpa;P2=Cpa;Q2=Dpa}else{var Epa=$CLJS.C(T2);$CLJS.lV(Epa,o2);O2=$CLJS.D(T2);P2=null;Q2=0}R2=0}else break}
$CLJS.z1.o(null,$CLJS.NZ,function(a,b){var c=$CLJS.Rf($CLJS.NZ.g($CLJS.V0(a,b)));return $CLJS.m(c)?$CLJS.UH("Filtered by {0}",$CLJS.G([$CLJS.c2($CLJS.VH("and"),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.a1.D(a,b,v,$CLJS.b1);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);
return $CLJS.nf($CLJS.a1.D(a,b,q,$CLJS.b1),f($CLJS.zd(g)))}return null}},null,null)}(c)}())])):null});
$CLJS.w1.o(null,r2,function(a,b,c,d){c=$CLJS.A(c);var e=$CLJS.C(c);c=$CLJS.D(c);$CLJS.C(c);var f=$CLJS.D(c);return $CLJS.c2(function(){var g=e instanceof $CLJS.N?e.S:null;switch(g){case "and":return $CLJS.VH("and");case "or":return $CLJS.VH("or");default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}(),function(){return function n(l){return new $CLJS.yf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<
v){var B=$CLJS.be(u,y);B=$CLJS.a1.D(a,b,B,d);x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.a1.D(a,b,x,d),n($CLJS.zd(q)))}return null}},null,null)}(f)}())});
$CLJS.w1.o(null,p2,function(a,b,c,d){var e=$CLJS.A(c);c=$CLJS.C(e);e=$CLJS.D(e);$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.rg.h(function(g){return $CLJS.a1.D(a,b,g,d)},f);if($CLJS.F.h($CLJS.E(f),2))switch(f=$CLJS.J(e,0,null),e=$CLJS.J(e,1,null),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.UH("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.UH("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}else switch(f=$CLJS.C(e),e=$CLJS.c2($CLJS.VH("or"),
$CLJS.zd(e)),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.UH("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.UH("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.w1.o(null,s2,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);var f=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);f=$CLJS.a1.D(a,b,f,d);a=$CLJS.a1.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "\x3c":return $CLJS.UH("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.UH("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.UH("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.UH("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.UH("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.UH("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.UH("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.UH("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});
$CLJS.w1.o(null,$CLJS.JI,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.UH("{0} is between {1} and {2}",$CLJS.G([$CLJS.a1.D(a,b,e,d),$CLJS.a1.D(a,b,f,d),$CLJS.a1.D(a,b,c,d)]))});
$CLJS.w1.o(null,$CLJS.UI,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.J(c,2,null),g=$CLJS.J(c,3,null),l=$CLJS.J(c,4,null),n=$CLJS.J(c,5,null),q=$CLJS.J(c,6,null);c=$CLJS.J(c,7,null);return $CLJS.a1.D(a,b,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,e,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.JI,e,f,q,l],null),new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.JI,e,g,n,c],null)],null),d)});
$CLJS.w1.o(null,o2,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.a1.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "is-null":return $CLJS.UH("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.UH("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.UH("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.UH("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.UH("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.g(e)].join(""));}});$CLJS.w1.o(null,$CLJS.qK,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.UH("{0} is within {1}",$CLJS.G([$CLJS.a1.D(a,b,e,d),$CLJS.n2(f,c).toLowerCase()]))});$CLJS.w1.o(null,$CLJS.iY,function(a,b,c){a=$CLJS.Qf(c);return $CLJS.M.h(a,$CLJS.TI)});
$CLJS.C1.o(null,$CLJS.iY,function(a,b,c){b=$CLJS.Qf(c);a=$CLJS.M.h(b,$CLJS.AV);b=$CLJS.M.h(b,$CLJS.TI);return new $CLJS.k(null,2,[$CLJS.u1,$CLJS.jG(a),$CLJS.TI,b],null)});