var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./schema.utils.js");require("./schema.spec.core.js");require("./schema.spec.leaf.js");require("./schema.spec.variant.js");require("./schema.spec.collection.js");
'use strict';var Lga,OM,PM,RM,TM,VM,Mga,XM,ZM,$M,Nga,aN,Oga,bN,Rga,Sga,eN,fN,iN,Vga,Wga,Xga,cN,Yga,Zga,dN,$ga,lN,Pga,bha,nN,eha,hN,gha,Kga,jha,Qga,lha,mha,sN,nha,oha,pha,uN,vN,wN,yN,zN,sha,tha,uha;$CLJS.LM=function(){return null};$CLJS.MM=function(a){if(null!=a&&null!=a.Db)a=a.Db(a);else{var b=$CLJS.MM[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=$CLJS.MM._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("Schema.spec",a);}return a};
$CLJS.NM=function(a){if(null!=a&&null!=a.Fa)a=a.Fa(a);else{var b=$CLJS.NM[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=$CLJS.NM._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("Schema.explain",a);}return a};Lga=function(a,b){return $CLJS.jM(a,function(c){var d=null!=c;return d?(d=b===c.constructor)?d:c instanceof b:d},function(c){return new $CLJS.gf(null,Kga,new $CLJS.gf(null,b,new $CLJS.gf(null,c,null,1,null),2,null),3,null)})};
OM=function(a,b,c,d){this.ic=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};PM=function(a,b,c,d){this.ua=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};$CLJS.QM=function(a){return new PM(a,null,null,null)};RM=function(a,b,c,d,e){this.ec=a;this.Jc=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};$CLJS.SM=function(a,b){if(!$CLJS.ze(a))throw Error($CLJS.UL("Not a function: %s",$CLJS.G([a])));return new RM(a,b,null,null,null)};
TM=function(a,b,c,d,e){this.X=a;this.name=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};$CLJS.UM=function(a,b){return new TM(a,b,null,null,null)};VM=function(a,b,c,d,e,f){this.X=a;this.Ic=b;this.vc=c;this.F=d;this.m=e;this.G=f;this.C=2230716170;this.K=139264};Mga=function(a,b){return $CLJS.WM(a,b,$CLJS.Ui.g($CLJS.XL(b)))};$CLJS.WM=function(a,b,c){if(!$CLJS.ze(b))throw Error($CLJS.UL("Not a function: %s",$CLJS.G([b])));return new VM(a,b,c,null,null,null)};
XM=function(a){return a instanceof $CLJS.N||!1};$CLJS.YM=function(a,b,c,d){this.k=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};ZM=function(a){if(a instanceof $CLJS.N)return a;if(a instanceof $CLJS.YM)return a.k;throw Error($CLJS.UL("Bad explicit key: %s",$CLJS.G([a])));};$M=function(a){return XM(a)||a instanceof $CLJS.YM};Nga=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.Je(b,a)};
aN=function(a,b,c,d,e){this.Cb=a;this.Mc=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};Oga=function(a){a=$CLJS.Rm.h($M,$CLJS.gi(a));if(!(2>$CLJS.E(a)))throw Error($CLJS.UL("More than one non-optional/required key schemata: %s",$CLJS.G([$CLJS.Mg(a)])));return $CLJS.C(a)};bN=function(a){return $M(a)?a instanceof $CLJS.N?a:new $CLJS.gf(null,XM(a)?Pga:a instanceof $CLJS.YM?Qga:null,new $CLJS.gf(null,ZM(a),null,1,null),2,null):$CLJS.NM(a)};
Rga=function(a){var b=Oga(a),c=$CLJS.Sm.h(bN,$CLJS.R.h($CLJS.qg,$CLJS.ug(function(e){return 1<$CLJS.E(e)},$CLJS.ii($CLJS.fj(ZM,$CLJS.gi($CLJS.Gm.h(a,b)))))));if(!$CLJS.je(c))throw Error($CLJS.UL("Schema has multiple variants of the same explicit key: %s",$CLJS.G([c])));var d=$CLJS.Gm.h(a,b);return $CLJS.qg.h(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){var n=l;if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);return function(){for(var H=
0;;)if(H<u){var I=$CLJS.be(q,H),Q=$CLJS.J(I,0,null),Y=$CLJS.J(I,1,null);$CLJS.Ff(v,function(){var aa=ZM(Q),ha=XM(Q);return $CLJS.HM(ha,new aN($CLJS.QM(aa),Y,null,null,null),function(qa,Ea,jb){return function(lb,Fb){var Mb=$CLJS.Ke(Fb,Ea);if($CLJS.m(Mb))lb.g?lb.g(Mb):lb.call(null,Mb);else if(jb){var ac=$CLJS.fM(new $CLJS.S(null,2,5,$CLJS.T,[Ea,cN],null));lb.g?lb.g(ac):lb.call(null,ac)}return $CLJS.m(Mb)?$CLJS.Gm.h(Fb,Ea):Fb}}(H,aa,ha,I,Q,Y,q,u,v,n,l,d,b))}());H+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(v),
g($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}var x=$CLJS.C(n),y=$CLJS.J(x,0,null),B=$CLJS.J(x,1,null);return $CLJS.nf(function(){var H=ZM(y),I=XM(y);return $CLJS.HM(I,new aN($CLJS.QM(H),B,null,null,null),function(Q,Y){return function(aa,ha){var qa=$CLJS.Ke(ha,Q);if($CLJS.m(qa))aa.g?aa.g(qa):aa.call(null,qa);else if(Y){var Ea=$CLJS.fM(new $CLJS.S(null,2,5,$CLJS.T,[Q,cN],null));aa.g?aa.g(Ea):aa.call(null,Ea)}return $CLJS.m(qa)?$CLJS.Gm.h(ha,Q):ha}}(H,I,x,y,B,n,l,d,b))}(),g($CLJS.zd(n)))}return null}},
null,null)}(d)}(),$CLJS.m(b)?function(){var e=$CLJS.si($CLJS.rg.h(ZM,$CLJS.gi(d))),f=$CLJS.Ke(a,b),g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);g=Mga(g,function(l){return!$CLJS.Ie(e,l)});return new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.FM(new aN(g,f,null,null,null))],null)}():null)};
Sga=function(){return function(a,b,c){return $CLJS.oh.h($CLJS.P,$CLJS.qg.h($CLJS.eg($CLJS.hM,b),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u),x=$CLJS.J(v,0,null);$CLJS.J(v,1,null);q.add(new $CLJS.S(null,2,5,$CLJS.T,[x,dN],null));u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);l=$CLJS.J(q,
0,null);$CLJS.J(q,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[l,dN],null),f($CLJS.zd(g)))}return null}},null,null)}(c)}()))}};eN=function(a){return $CLJS.EM($CLJS.jM(a,$CLJS.oe,function(b){return new $CLJS.gf(null,$CLJS.gk,new $CLJS.gf(null,b,null,1,null),2,null)}),function(b){return $CLJS.oh.h($CLJS.P,b)},Rga(a),Sga())};
fN=function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[bN(u),$CLJS.NM(q)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,
2,5,$CLJS.T,[bN(l),$CLJS.NM(f)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())};$CLJS.gN=function(a,b,c,d,e,f){this.X=a;this.tc=b;this.name=c;this.F=d;this.m=e;this.G=f;this.C=2230716170;this.K=139264};
iN=function(a){var b=$CLJS.Bi(function(e){return e instanceof $CLJS.gN&&$CLJS.Gb(hN.g(e))},a),c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=$CLJS.Bi(function(e){var f=e instanceof $CLJS.gN;return f?hN.g(e):f},b);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);if(!(1>=$CLJS.E(d)&&$CLJS.Yf(function(e){return e instanceof $CLJS.gN},d)))throw Error($CLJS.UL("%s is not a valid sequence schema; %s%s%s",$CLJS.G([a,"a valid sequence schema consists of zero or more `one` elements, ","followed by zero or more `optional` elements, followed by an optional ",
"schema that will match the remaining elements."])));return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qg.h(c,b),$CLJS.C(d)],null)};$CLJS.Tga=new $CLJS.w(null,"some-matching-condition?","some-matching-condition?",1512398506,null);$CLJS.Uga=new $CLJS.w(null,"conditional","conditional",-1212542970,null);Vga=new $CLJS.w(null,"Uuid","Uuid",-1866694318,null);Wga=new $CLJS.w(null,"has-extra-elts?","has-extra-elts?",-1376562869,null);Xga=new $CLJS.w(null,"Int","Int",-2116888740,null);
cN=new $CLJS.w(null,"missing-required-key","missing-required-key",709961446,null);Yga=new $CLJS.w(null,"present?","present?",-1810613791,null);Zga=new $CLJS.w(null,"one","one",-1719427865,null);$CLJS.jN=new $CLJS.N(null,"schemas","schemas",575070579);dN=new $CLJS.w(null,"disallowed-key","disallowed-key",-1877785633,null);$CLJS.kN=new $CLJS.N(null,"input-schemas","input-schemas",-982154805);$ga=new $CLJS.w(null,"Keyword","Keyword",-850065993,null);lN=new $CLJS.N(null,"pred-name","pred-name",-3677451);
$CLJS.aha=new $CLJS.w(null,"\x3d\x3e*","\x3d\x3e*",1909690043,null);$CLJS.mN=new $CLJS.N(null,"output-schema","output-schema",272504137);Pga=new $CLJS.w(null,"required-key","required-key",1624616412,null);bha=new $CLJS.w(null,"re-find","re-find",1143444147,null);$CLJS.cha=new $CLJS.w(null,"cond-pre","cond-pre",-923707731,null);$CLJS.dha=new $CLJS.N("schema.core","error","schema.core/error",1991454308);nN=new $CLJS.N(null,"p?","p?",-1172161701);
eha=new $CLJS.w(null,"constrained","constrained",-2057147788,null);hN=new $CLJS.N(null,"optional?","optional?",1184638129);$CLJS.oN=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);$CLJS.pN=new $CLJS.N(null,"vs","vs",-2022097090);$CLJS.fha=new $CLJS.w(null,"\x3d\x3e","\x3d\x3e",-813269641,null);$CLJS.qN=new $CLJS.N(null,"error-symbol","error-symbol",-823480428);gha=new $CLJS.w(null,"Inst","Inst",292408622,null);$CLJS.hha=new $CLJS.w(null,"recursive","recursive",-1935549897,null);
Kga=new $CLJS.w(null,"instance?","instance?",1075939923,null);$CLJS.iha=new $CLJS.w(null,"maybe","maybe",1326133967,null);jha=new $CLJS.N("schema.core","missing","schema.core/missing",1420181325);$CLJS.kha=new $CLJS.w(null,"matches-some-precondition?","matches-some-precondition?",2123072832,null);$CLJS.rN=new $CLJS.N(null,"preds-and-schemas","preds-and-schemas",-1306766355);Qga=new $CLJS.w(null,"optional-key","optional-key",988406145,null);lha=new $CLJS.w(null,"Symbol","Symbol",716452869,null);
mha=new $CLJS.w(null,"Str","Str",907970895,null);sN=new $CLJS.N(null,"postcondition","postcondition",-737101222);$CLJS.tN=new $CLJS.N(null,"derefable","derefable",377265868);nha=new $CLJS.w(null,"eq","eq",1021992460,null);oha=new $CLJS.w(null,"Bool","Bool",195910502,null);pha=new $CLJS.w(null,"invalid-key","invalid-key",-1461682245,null);uN=new $CLJS.N(null,"key-schema","key-schema",-1096660726);vN=new $CLJS.N(null,"post-name","post-name",491455269);wN=new $CLJS.N(null,"val-schema","val-schema",-2014773619);
$CLJS.xN=new $CLJS.w(null,"optional","optional",-600484260,null);$CLJS.qha=new $CLJS.w(null,"enum","enum",-975417337,null);yN=new $CLJS.N(null,"v","v",21465059);$CLJS.rha=new $CLJS.w(null,"...","...",-1926939749,null);zN=new $CLJS.N(null,"_","_",1453416199);sha=new $CLJS.w(null,"Any","Any",1277492269,null);tha=new $CLJS.w(null,"map-entry","map-entry",329617471,null);uha=new $CLJS.w(null,"Num","Num",-2044934708,null);$CLJS.MM["function"]=function(a){var b=Lga(a,a);a=$CLJS.Ua(a,"schema$utils$schema");return $CLJS.m(a)?$CLJS.xM(b,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.fm,a],null)],null)):$CLJS.rM(b)};
$CLJS.NM["function"]=function(a){var b=$CLJS.Ua(a,"schema$utils$schema");return $CLJS.m(b)?$CLJS.NM(b):$CLJS.m($CLJS.F.h?$CLJS.F.h(Boolean,a):$CLJS.F.call(null,Boolean,a))?oha:$CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a))?uha:$CLJS.m($CLJS.F.h?$CLJS.F.h(Date,a):$CLJS.F.call(null,Date,a))?gha:$CLJS.m($CLJS.F.h?$CLJS.F.h($CLJS.Hu,a):$CLJS.F.call(null,$CLJS.Hu,a))?Vga:a};$CLJS.h=OM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "_":return this.ic;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.AnythingSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[zN,this.ic],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[zN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1432036169^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ic,b.ic)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[zN,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new OM(this.ic,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "_":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(zN,b):$CLJS.O.call(null,zN,b))?new OM(c,this.F,this.m,null):new OM(this.ic,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je(zN,this.ic)],null),this.m))};$CLJS.h.O=function(a,b){return new OM(this.ic,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.rM($CLJS.LM)};$CLJS.h.Fa=function(){return sha};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.AN=new OM(null,null,null,null);$CLJS.h=PM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "v":return this.ua;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.EqSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[yN,this.ua],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[yN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-907989292^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ua,b.ua)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[yN,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new PM(this.ua,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "v":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(yN,b):$CLJS.O.call(null,yN,b))?new PM(c,this.F,this.m,null):new PM(this.ua,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je(yN,this.ua)],null),this.m))};$CLJS.h.O=function(a,b){return new PM(this.ua,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){var a=this;return $CLJS.rM($CLJS.jM(this,function(b){return $CLJS.F.h(a.ua,b)},function(b){return new $CLJS.gf(null,$CLJS.oN,new $CLJS.gf(null,a.ua,new $CLJS.gf(null,b,null,1,null),2,null),3,null)}))};$CLJS.h.Fa=function(){return new $CLJS.gf(null,nha,new $CLJS.gf(null,this.ua,null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
$CLJS.h=RM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "p?":return this.ec;case "pred-name":return this.Jc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Predicate{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[nN,this.ec],null),new $CLJS.S(null,2,5,$CLJS.T,[lN,this.Jc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[nN,lN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=2041221968^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ec,b.ec)&&$CLJS.F.h(this.Jc,b.Jc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[lN,null,nN,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new RM(this.ec,this.Jc,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "p?":case "pred-name":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(nN,b):$CLJS.O.call(null,nN,b))?new RM(c,this.Jc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(lN,b):$CLJS.O.call(null,lN,b))?new RM(this.ec,c,this.F,this.m,null):new RM(this.ec,this.Jc,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je(nN,this.ec),new $CLJS.Je(lN,this.Jc)],null),this.m))};$CLJS.h.O=function(a,b){return new RM(this.ec,this.Jc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){var a=this;return $CLJS.rM($CLJS.jM(this,a.ec,function(b){return new $CLJS.gf(null,a.Jc,new $CLJS.gf(null,b,null,1,null),2,null)}))};
$CLJS.h.Fa=function(){return $CLJS.F.h(this.ec,$CLJS.Ae)?Xga:$CLJS.F.h(this.ec,$CLJS.pf)?$ga:$CLJS.F.h(this.ec,$CLJS.rd)?lha:$CLJS.F.h(this.ec,$CLJS.Jb)?mha:new $CLJS.gf(null,$CLJS.sm,new $CLJS.gf(null,this.Jc,null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
RegExp.prototype.Db=function(){var a=this;return $CLJS.rM($CLJS.Qm.h($CLJS.jM(a,$CLJS.Jb,function(b){return new $CLJS.gf(null,$CLJS.Xl,new $CLJS.gf(null,b,null,1,null),2,null)}),$CLJS.jM(a,function(b){return $CLJS.Ei(a,b)},function(b){return new $CLJS.gf(null,bha,new $CLJS.gf(null,$CLJS.NM(a),new $CLJS.gf(null,b,null,1,null),2,null),3,null)})))};RegExp.prototype.Fa=function(){return $CLJS.Ui.g(['#"',$CLJS.p.g($CLJS.p.g(this).slice(1,-1)),'"'].join(""))};$CLJS.BN=$CLJS.SM($CLJS.Jb,$CLJS.Xl);
$CLJS.CN=Boolean;$CLJS.DN=Number;$CLJS.EN=$CLJS.SM($CLJS.Ae,$CLJS.um);$CLJS.FN=$CLJS.SM($CLJS.pf,$CLJS.Ek);$CLJS.SM($CLJS.rd,$CLJS.Xj);$CLJS.h=TM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;case "name":return this.name;default:return $CLJS.M.j(this.m,b,c)}};
$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.NamedSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,this.X],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,this.name],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.V],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=456601728^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.name,b.name)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.fm,null,$CLJS.V,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new TM(this.X,this.name,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":case "name":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.fm,b):$CLJS.O.call(null,$CLJS.fm,b))?new TM(c,this.name,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.V,b):$CLJS.O.call(null,$CLJS.V,b))?new TM(this.X,c,this.F,this.m,null):new TM(this.X,this.name,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je($CLJS.fm,this.X),new $CLJS.Je($CLJS.V,this.name)],null),this.m))};
$CLJS.h.O=function(a,b){return new TM(this.X,this.name,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){var a=this;return $CLJS.xM($CLJS.LM,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.fm,a.X,$CLJS.uM,function(b){return new $CLJS.bM(a.name,b)}],null)],null))};
$CLJS.h.Fa=function(){return new $CLJS.gf(null,$CLJS.cM,new $CLJS.gf(null,$CLJS.NM(this.X),new $CLJS.gf(null,this.name,null,1,null),2,null),3,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.qM.prototype.Sd=$CLJS.La(59);$CLJS.vM.prototype.Sd=$CLJS.La(58);$CLJS.DM.prototype.Sd=$CLJS.La(57);$CLJS.h=VM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;case "postcondition":return this.Ic;case "post-name":return this.vc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Constrained{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,this.X],null),new $CLJS.S(null,2,5,$CLJS.T,[sN,this.Ic],null),new $CLJS.S(null,2,5,$CLJS.T,[vN,this.vc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,3,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fm,sN,vN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};
$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 3+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=725895648^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.Ic,b.Ic)&&$CLJS.F.h(this.vc,b.vc)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.fm,null,vN,null,sN,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new VM(this.X,this.Ic,this.vc,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":case "postcondition":case "post-name":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.fm,b):$CLJS.O.call(null,$CLJS.fm,b))?new VM(c,this.Ic,this.vc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(sN,b):$CLJS.O.call(null,sN,b))?new VM(this.X,c,this.vc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(vN,b):$CLJS.O.call(null,vN,b))?new VM(this.X,this.Ic,c,this.F,this.m,null):new VM(this.X,this.Ic,this.vc,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.Je($CLJS.fm,this.X),new $CLJS.Je(sN,this.Ic),new $CLJS.Je(vN,this.vc)],null),this.m))};$CLJS.h.O=function(a,b){return new VM(this.X,this.Ic,this.vc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.h.Db=function(){var a=this;return $CLJS.yM($CLJS.LM,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.fm,a.X],null)],null),null,$CLJS.jM(this,a.Ic,function(b){return new $CLJS.gf(null,a.vc,new $CLJS.gf(null,b,null,1,null),2,null)}))};$CLJS.h.Fa=function(){return new $CLJS.gf(null,eha,new $CLJS.gf(null,$CLJS.NM(this.X),new $CLJS.gf(null,this.vc,null,1,null),2,null),3,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.h=$CLJS.YM.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "k":return this.k;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.OptionalKey{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$u,this.k],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.$u],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1508333161^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.k,b.k)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.$u,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $CLJS.YM(this.k,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "k":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.$u,b):$CLJS.O.call(null,$CLJS.$u,b))?new $CLJS.YM(c,this.F,this.m,null):new $CLJS.YM(this.k,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.$u,this.k)],null),this.m))};$CLJS.h.O=function(a,b){return new $CLJS.YM(this.k,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=aN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "key-schema":return this.Cb;case "val-schema":return this.Mc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.MapEntry{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[uN,this.Cb],null),new $CLJS.S(null,2,5,$CLJS.T,[wN,this.Mc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[uN,wN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-804356212^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Cb,b.Cb)&&$CLJS.F.h(this.Mc,b.Mc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[uN,null,wN,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new aN(this.Cb,this.Mc,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "key-schema":case "val-schema":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(uN,b):$CLJS.O.call(null,uN,b))?new aN(c,this.Mc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(wN,b):$CLJS.O.call(null,wN,b))?new aN(this.Cb,c,this.F,this.m,null):new aN(this.Cb,this.Mc,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je(uN,this.Cb),new $CLJS.Je(wN,this.Mc)],null),this.m))};$CLJS.h.O=function(a,b){return new aN(this.Cb,this.Mc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.h.Db=function(){return $CLJS.EM($CLJS.LM,Nga,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HM(!0,this.Cb,function(a,b){var c=$CLJS.tc(b);a.g?a.g(c):a.call(null,c);return b}),$CLJS.HM(!0,this.Mc,function(a,b){b=$CLJS.uc(b);a.g?a.g(b):a.call(null,b);return null})],null),function(a,b){a=$CLJS.J(a,0,null);var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=$CLJS.hM(c);return $CLJS.m(c)?new $CLJS.S(null,2,5,$CLJS.T,[c,pha],null):new $CLJS.S(null,2,5,$CLJS.T,[a,$CLJS.hM(b)],null)})};
$CLJS.h.Fa=function(){return new $CLJS.gf(null,tha,new $CLJS.gf(null,$CLJS.NM(this.Cb),new $CLJS.gf(null,$CLJS.NM(this.Mc),null,1,null),2,null),3,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.k.prototype.Db=function(){return eN(this)};$CLJS.k.prototype.Fa=function(){return fN(this)};$CLJS.Rh.prototype.Db=function(){return eN(this)};$CLJS.Rh.prototype.Fa=function(){return fN(this)};
$CLJS.ni.prototype.Db=function(){if(!$CLJS.F.h($CLJS.E(this),1))throw Error($CLJS.VL("Set schema must have exactly one element"));return $CLJS.EM($CLJS.jM(this,$CLJS.le,function(a){return new $CLJS.gf(null,$CLJS.il,new $CLJS.gf(null,a,null,1,null),2,null)}),$CLJS.si,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.FM($CLJS.C(this))],null),function(a,b){return $CLJS.si($CLJS.eg($CLJS.hM,b))})};$CLJS.ni.prototype.Fa=function(){return $CLJS.si(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.NM($CLJS.C(this))],null))};
$CLJS.h=$CLJS.gN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;case "optional?":return this.tc;case "name":return this.name;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.One{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,this.X],null),new $CLJS.S(null,2,5,$CLJS.T,[hN,this.tc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,this.name],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,3,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fm,hN,$CLJS.V],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};
$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 3+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-197981045^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.tc,b.tc)&&$CLJS.F.h(this.name,b.name)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.fm,null,$CLJS.V,null,hN,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $CLJS.gN(this.X,this.tc,this.name,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":case "optional?":case "name":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.fm,b):$CLJS.O.call(null,$CLJS.fm,b))?new $CLJS.gN(c,this.tc,this.name,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(hN,b):$CLJS.O.call(null,hN,b))?new $CLJS.gN(this.X,c,this.name,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.V,b):$CLJS.O.call(null,$CLJS.V,b))?new $CLJS.gN(this.X,this.tc,c,this.F,this.m,null):new $CLJS.gN(this.X,this.tc,this.name,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.Je($CLJS.fm,this.X),new $CLJS.Je(hN,this.tc),new $CLJS.Je($CLJS.V,this.name)],null),this.m))};$CLJS.h.O=function(a,b){return new $CLJS.gN(this.X,this.tc,this.name,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.S.prototype.Db=function(){var a=this;return $CLJS.EM($CLJS.jM(a,function(b){return null==b||$CLJS.ne(b)},function(b){return new $CLJS.gf(null,$CLJS.hl,new $CLJS.gf(null,b,null,1,null),2,null)}),$CLJS.Mg,function(){var b=iN(a),c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.Sb(function(d,e){return $CLJS.Gb(e.tc)?$CLJS.nf($CLJS.HM(!0,$CLJS.UM(e.X,e.name),function(f,g){if(g=$CLJS.A(g)){var l=$CLJS.C(g);f.g?f.g(l):f.call(null,l);return $CLJS.zd(g)}g=$CLJS.fM($CLJS.aM(e.X,jha,new $CLJS.Yi(function(){return new $CLJS.gf(null,
Yga,new $CLJS.gf(null,e.name,null,1,null),2,null)}),null));f.g?f.g(g):f.call(null,g);return null}),d):new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Jga($CLJS.UM(e.X,e.name),function(f,g){if(g=$CLJS.A(g)){var l=$CLJS.C(g);f.g?f.g(l):f.call(null,l);return $CLJS.zd(g)}return null},d)],null)},$CLJS.m(b)?new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.FM(b)],null):null,$CLJS.lf(c))}(),function(b,c,d){b=$CLJS.Sm.h($CLJS.hM,c);return $CLJS.A(d)?$CLJS.kf.h(b,$CLJS.hM($CLJS.fM($CLJS.aM(null,d,new $CLJS.Yi(function(){return new $CLJS.gf(null,
Wga,new $CLJS.gf(null,$CLJS.E(d),null,1,null),2,null)}),null)))):b})};$CLJS.S.prototype.Fa=function(){var a=iN(this),b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);b=$CLJS.Sm.h(function(c){return new $CLJS.gf(null,$CLJS.m(c.tc)?$CLJS.xN:Zga,new $CLJS.gf(null,$CLJS.NM($CLJS.fm.g(c)),new $CLJS.gf(null,$CLJS.V.g(c),null,1,null),2,null),3,null)},b);return $CLJS.m(a)?$CLJS.kf.h(b,$CLJS.NM(a)):b};$CLJS.cj.g(!1);