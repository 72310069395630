var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var r9,t9,s9,Gva,y9,z9,Hva,Iva,Jva,Kva,u9,Lva,D9,Mva,Nva,Ova;r9=function(a,b){return $CLJS.m(a)?$CLJS.Ua(a,b):null};t9=function(a,b){return s9(a,b,$CLJS.P)};
s9=function(a,b,c){c=$CLJS.Qf(c);c=$CLJS.M.j(c,u9,!0);if($CLJS.oe(b))return $CLJS.oh.j($CLJS.P,a,b);c=$CLJS.m(c)?function(){var d=r9(b,"_plainObject");d=null==d?null:$CLJS.iF(d);return null==d?null:$CLJS.Rf(d)}():null;return $CLJS.m(c)?$CLJS.oh.j($CLJS.P,a,c):$CLJS.oh.j($CLJS.P,$CLJS.Om.j($CLJS.rg.g(function(d){return new $CLJS.S(null,2,5,$CLJS.T,[d,r9(b,d)],null)}),$CLJS.Rm.g(function(d){$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.F.h($CLJS.Na(d),"function")}),a),$CLJS.Ta(b))};
Gva=function(a){var b=v9.g(a),c=w9.g(a);return $CLJS.Om.j($CLJS.rg.g(function(d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Oi.g((0,$CLJS.oG)(e)),d],null)}),$CLJS.je(b)?$CLJS.Ye:$CLJS.Rm.g(function(d){var e=$CLJS.J(d,0,null);$CLJS.J(d,1,null);return $CLJS.Ie(b,e)}),$CLJS.Gb(c)?$CLJS.Ye:$CLJS.rg.g(function(d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[e,c.h?c.h(e,d):c.call(null,e,d)],null)}))};
y9=function(a,b){var c=Gva(a),d=x9.g(a);return function(e){try{var f=$CLJS.U.j(s9(c,e,b),$CLJS.tl,d),g=$CLJS.YF($CLJS.WD);$CLJS.m($CLJS.eG("metabase.lib.js.metadata",g))&&$CLJS.fG("metabase.lib.js.metadata",g,$CLJS.Y_("Parsed metadata %s %s\n%s",$CLJS.G([a,$CLJS.Ik.g(f),$CLJS.Z_(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,g=$CLJS.YF($CLJS.Xu),$CLJS.m($CLJS.eG("metabase.lib.js.metadata",g))&&(f instanceof Error?$CLJS.fG("metabase.lib.js.metadata",g,$CLJS.Y_("Error parsing %s %s: %s",
$CLJS.G([a,$CLJS.aj.l($CLJS.G([e])),$CLJS.SZ(f)])),f):$CLJS.fG("metabase.lib.js.metadata",g,$CLJS.Y_(f,$CLJS.G(["Error parsing %s %s: %s",a,$CLJS.aj.l($CLJS.G([e])),$CLJS.SZ(f)])),null)),null;throw l;}}};z9=function(a){return $CLJS.Sm.h(y9($CLJS.DQ,$CLJS.P),a)};Hva=new $CLJS.N(null,"plain-object","plain-object",-943264281);$CLJS.A9=new $CLJS.N(null,"cards","cards",169174038);Iva=new $CLJS.N(null,"db","db",993250759);$CLJS.B9=new $CLJS.N(null,"metrics","metrics",394093469);
Jva=new $CLJS.N(null,"fks","fks",2041987076);$CLJS.C9=new $CLJS.N(null,"segments","segments",1937535949);Kva=new $CLJS.N(null,"dimensions","dimensions",-254818097);u9=new $CLJS.N(null,"use-plain-object?","use-plain-object?",-1174595475);Lva=new $CLJS.N(null,"metadata","metadata",1799301597);D9=new $CLJS.N(null,"dimension-options","dimension-options",2122379680);Mva=new $CLJS.N(null,"schema-name","schema-name",1666725119);Nva=new $CLJS.N(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);
Ova=new $CLJS.N(null,"default-dimension-option","default-dimension-option",149055958);$CLJS.E9=new $CLJS.N(null,"databases","databases",2040134125);var v9,Pva=$CLJS.cj.g($CLJS.P),Qva=$CLJS.cj.g($CLJS.P),Rva=$CLJS.cj.g($CLJS.P),Sva=$CLJS.cj.g($CLJS.P),Tva=$CLJS.M.j($CLJS.P,$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null)),G9,dwa,ewa,fwa,gwa,hwa;v9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","excluded-keys"),$CLJS.Oi,$CLJS.Pj,Tva,Pva,Qva,Rva,Sva);v9.o(null,$CLJS.Pj,function(){return null});
var w9,Uva=$CLJS.cj.g($CLJS.P),Vva=$CLJS.cj.g($CLJS.P),Wva=$CLJS.cj.g($CLJS.P),Xva=$CLJS.cj.g($CLJS.P),Yva=$CLJS.M.j($CLJS.P,$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));w9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","parse-field-fn"),$CLJS.Oi,$CLJS.Pj,Yva,Uva,Vva,Wva,Xva);w9.o(null,$CLJS.Pj,function(){return null});var x9,Zva=$CLJS.cj.g($CLJS.P),$va=$CLJS.cj.g($CLJS.P),awa=$CLJS.cj.g($CLJS.P),bwa=$CLJS.cj.g($CLJS.P),cwa=$CLJS.M.j($CLJS.P,$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
x9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","lib-type"),$CLJS.Oi,$CLJS.Pj,cwa,Zva,$va,awa,bwa);$CLJS.F9=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.Oi.g(f)},$CLJS.Pj,e,a,b,c,d)}();dwa=$CLJS.cj.g($CLJS.P);ewa=$CLJS.cj.g($CLJS.P);fwa=$CLJS.cj.g($CLJS.P);gwa=$CLJS.cj.g($CLJS.P);
hwa=$CLJS.M.j($CLJS.P,$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));G9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.Oi,$CLJS.Pj,hwa,dwa,ewa,fwa,gwa);$CLJS.F9.o(null,$CLJS.Pj,function(a,b){var c=y9(a,$CLJS.P);return t9($CLJS.rg.g(function(d){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oL(e),new $CLJS.Yi(function(){return c.g?c.g(f):c.call(null,f)})],null)}),r9(b,G9.g(a)))});x9.o(null,$CLJS.LQ,function(){return $CLJS.Noa});
v9.o(null,$CLJS.LQ,function(){return new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.wO,null,$CLJS.j0,null],null),null)});w9.o(null,$CLJS.LQ,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "dbms-version":return $CLJS.hF(b,$CLJS.G([$CLJS.hk,!0]));case "features":return $CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Oi),b);case "native-permissions":return $CLJS.Oi.g(b);default:return b}}});G9.o(null,$CLJS.LQ,function(){return"databases"});x9.o(null,$CLJS.l0,function(){return $CLJS.i0});
v9.o(null,$CLJS.l0,function(){return new $CLJS.ni(null,new $CLJS.k(null,5,[D9,null,$CLJS.wO,null,$CLJS.C9,null,$CLJS.LQ,null,$CLJS.B9,null],null),null)});w9.o(null,$CLJS.l0,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "entity-type":return $CLJS.Oi.g(b);case "field-order":return $CLJS.Oi.g(b);case "initial-sync-status":return $CLJS.Oi.g(b);case "visibility-type":return $CLJS.Oi.g(b);default:return b}}});
$CLJS.F9.o(null,$CLJS.l0,function(a,b){var c=y9(a,$CLJS.P);return t9($CLJS.Om.h($CLJS.Rm.g(function(d){var e=$CLJS.J(d,0,null);$CLJS.J(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.rg.g(function(d){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oL(e),new $CLJS.Yi(function(){return c.g?c.g(f):c.call(null,f)})],null)})),r9(b,"tables"))});x9.o(null,$CLJS.DQ,function(){return $CLJS.hW});
v9.o(null,$CLJS.DQ,function(){return new $CLJS.ni(null,new $CLJS.k(null,7,[D9,null,$CLJS.l0,null,Kva,null,Ova,null,$CLJS.LQ,null,Nva,null,$CLJS.B9,null],null),null)});
w9.o(null,$CLJS.DQ,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "base-type":return $CLJS.Oi.g(b);case "coercion-strategy":return $CLJS.Oi.g(b);case "effective-type":return $CLJS.Oi.g(b);case "fingerprint":return $CLJS.oe(b)?$CLJS.XZ(b):$CLJS.hF(b,$CLJS.G([$CLJS.hk,!0]));case "has-field-values":return $CLJS.Oi.g(b);case "semantic-type":return $CLJS.Oi.g(b);case "visibility-type":return $CLJS.Oi.g(b);case "id":return $CLJS.qe(b)?$CLJS.Zd(b):b;default:return b}}});
$CLJS.F9.o(null,$CLJS.DQ,function(a,b){var c=y9(a,$CLJS.P),d=r9(b,"fields");return t9($CLJS.Bn(function(e){var f=$CLJS.J(e,0,null),g=$CLJS.J(e,1,null);e=function(){var l=$CLJS.oL(f);if($CLJS.m(l))return l;l=$CLJS.Di(/card__\d+:(\d+)/,f);if($CLJS.m(l)){$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);var n=null==r9(d,l);return n?$CLJS.oL(l):n}return null}();return $CLJS.m(e)?new $CLJS.S(null,2,5,$CLJS.T,[e,new $CLJS.Yi(function(){return c.g?c.g(g):c.call(null,g)})],null):null}),d)});x9.o(null,$CLJS.PI,function(){return $CLJS.m0});
v9.o(null,$CLJS.PI,function(){return new $CLJS.ni(null,new $CLJS.k(null,11,[D9,null,$CLJS.fm,null,Jva,null,$CLJS.l0,null,Iva,null,Hva,null,$CLJS.C9,null,$CLJS.LQ,null,Lva,null,$CLJS.B9,null,Mva,null],null),null)});
w9.o(null,$CLJS.PI,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "result-metadata":return $CLJS.m($CLJS.Qm.h($CLJS.ne,$CLJS.Db)(b))?z9(b):$CLJS.hF(b,$CLJS.G([$CLJS.hk,!0]));case "fields":return z9(b);case "visibility-type":return $CLJS.Oi.g(b);case "dataset-query":return $CLJS.hF(b,$CLJS.G([$CLJS.hk,!0]));default:return b}}});
$CLJS.F9.o(null,$CLJS.PI,function(a,b){return $CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(c){return new $CLJS.S(null,2,5,$CLJS.T,[c,new $CLJS.Yi(function(){var d=y9($CLJS.PI,new $CLJS.k(null,1,[u9,!1],null)),e=y9($CLJS.PI,$CLJS.P),f=$CLJS.jn.l;d=$CLJS.U.j(d(r9(r9(b,"tables"),["card__",$CLJS.p.g(c)].join(""))),$CLJS.Ik,c);var g=r9(r9(b,"questions"),$CLJS.p.g(c));var l=r9(g,"_card");g=$CLJS.m(l)?l:g;e=e.g?e.g(g):e.call(null,g);return f.call($CLJS.jn,$CLJS.G([d,e]))})],null)}),$CLJS.oh.j($CLJS.oh.j($CLJS.oi,
$CLJS.Bn($CLJS.f0),$CLJS.Ta(r9(b,"tables"))),$CLJS.rg.g($CLJS.oL),$CLJS.Ta(r9(b,"questions"))))});x9.o(null,$CLJS.$P,function(){return $CLJS.iW});v9.o(null,$CLJS.$P,function(){return new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.l0,null,$CLJS.LQ,null],null),null)});w9.o(null,$CLJS.$P,function(){return function(a,b){return b}});G9.o(null,$CLJS.$P,function(){return"metrics"});x9.o(null,$CLJS.nI,function(){return $CLJS.h0});
v9.o(null,$CLJS.nI,function(){return new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.l0,null,$CLJS.LQ,null],null),null)});w9.o(null,$CLJS.nI,function(){return function(a,b){return b}});G9.o(null,$CLJS.nI,function(){return"segments"});