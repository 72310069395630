var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var nX,oX,xma,yma,zma,qX,rX,sX,tX,vX,Ama,xX;nX=function(a){switch(arguments.length){case 2:return $CLJS.nV(arguments[0],arguments[1]);case 3:return $CLJS.mV(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};oX=function(a){return["(?:",$CLJS.p.g($CLJS.R.h($CLJS.p,a)),")?"].join("")};xma=new $CLJS.N("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
yma=new $CLJS.N("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.pX=new $CLJS.N("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);zma=new $CLJS.N("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);qX=new $CLJS.N("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
rX=new $CLJS.N("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);sX=new $CLJS.N("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);tX=new $CLJS.N("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.uX=new $CLJS.N("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
vX=new $CLJS.N("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);Ama=new $CLJS.N("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.wX=new $CLJS.N("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);xX=new $CLJS.N("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.yX=new $CLJS.N("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.AL.o(null,$CLJS.GG,function(){return $CLJS.pl});$CLJS.jL(xX,$CLJS.mk);$CLJS.AL.o(null,$CLJS.MG,function(){return $CLJS.Dl});$CLJS.jL(xX,$CLJS.mk);$CLJS.jL(Ama,$CLJS.dk);$CLJS.AL.o(null,$CLJS.vG,function(){return $CLJS.kl});$CLJS.jL(yma,$CLJS.St);$CLJS.AL.o(null,$CLJS.tG,function(){return $CLJS.KK});$CLJS.jL(zma,$CLJS.Rl);
var Bma=[":\\d{2}",oX($CLJS.G(["\\.\\d{1,6}"]))].join(""),zX=["\\d{2}:\\d{2}",oX($CLJS.G([Bma]))].join(""),AX=["\\d{4}-\\d{2}-\\d{2}T",zX].join(""),BX=["(?:Z|(?:[+-]",zX,"))"].join(""),Cma=$CLJS.Fi("^\\d{4}-\\d{2}-\\d{2}$"),Dma=$CLJS.Fi(["^",zX,"$"].join("")),Ema=$CLJS.Fi(["^",zX,BX,"$"].join("")),Fma=$CLJS.Fi(["^",AX,"$"].join("")),Gma=$CLJS.Fi(["^",AX,BX,"$"].join(""));$CLJS.jL(sX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.ev,"date string literal"],null),Cma],null));
$CLJS.jL(rX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Dk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.ev,"local time string literal"],null),Dma],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.ev,"offset time string literal"],null),Ema],null)],null));
$CLJS.jL(tX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Dk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.ev,"local date time string literal"],null),Fma],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.ev,"offset date time string literal"],null),Gma],null)],null));
$CLJS.AL.o(null,$CLJS.uG,function(a){return $CLJS.m($CLJS.nV?$CLJS.nV(tX,a):nX.call(null,tX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Vl,null,$CLJS.SJ,null],null),null):$CLJS.m($CLJS.nV?$CLJS.nV(sX,a):nX.call(null,sX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.OI,null,$CLJS.Vl,null],null),null):$CLJS.m($CLJS.nV?$CLJS.nV(rX,a):nX.call(null,rX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.XK,null,$CLJS.Vl,null],null),null):$CLJS.Vl});$CLJS.jL($CLJS.uX,sX);$CLJS.jL(qX,rX);
$CLJS.jL($CLJS.yX,tX);$CLJS.jL(xma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Dk,$CLJS.uX,qX,$CLJS.yX],null));$CLJS.CX=$CLJS.Fi("^\\d{4}-\\d{2}$");$CLJS.jL($CLJS.wX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.ev,"year-month string literal"],null),$CLJS.CX],null));$CLJS.DX=$CLJS.Fi("^\\d{4}$");$CLJS.jL($CLJS.pX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.ev,"year string literal"],null),$CLJS.DX],null));
$CLJS.jL(vX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.wL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nk,$CLJS.xL],null)],null)],null));$CLJS.sV($CLJS.sl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ek,new $CLJS.k(null,1,[$CLJS.ev,"Value :value clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,$CLJS.sl],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,vX],null),$CLJS.Lb],null));