var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Woa,R0,Xoa,S0,T0,l1,Yoa,Zoa,p1,e1,r1,$oa,g1;Woa=function(a){return $CLJS.A(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};R0=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Ie($CLJS.Afa,a)?a:"_":$CLJS.m(b)?encodeURIComponent(a):a};
Xoa=function(a){var b=$CLJS.Qf(new $CLJS.k(null,1,[$CLJS.FG,!0],null)),c=$CLJS.M.h(b,$CLJS.zfa),d=$CLJS.M.h(b,$CLJS.FG);return $CLJS.A(a)?(b=$CLJS.cv.g(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v);x=R0(x,$CLJS.Gb(d));u.add(x);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}u=$CLJS.C(l);return $CLJS.nf(R0(u,
$CLJS.Gb(d)),g($CLJS.zd(l)))}return null}},null,null)}(Woa($CLJS.kG(a)))}()),$CLJS.m(c)?$CLJS.cv.g($CLJS.jg(c,b)):b):null};S0=function(a,b){if(null!=a&&null!=a.qh)a=a.qh(a,b);else{var c=S0[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=S0._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.table",a);}return a};
T0=function(a,b){if(null!=a&&null!=a.jh)a=a.jh(a,b);else{var c=T0[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=T0._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.card",a);}return a};$CLJS.U0=function(a,b){var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.zj($CLJS.UH("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.k(null,1,[$CLJS.Moa,$CLJS.E(a)],null));return c};
$CLJS.V0=function(a,b){a=$CLJS.e0(a);a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.hZ);return $CLJS.M.h($CLJS.Mg(a),$CLJS.U0(a,b))};$CLJS.W0=function(a,b,c,d){a=$CLJS.e0(a);a=$CLJS.Qf(a);var e=$CLJS.M.h(a,$CLJS.hZ);b=$CLJS.U0(e,b);c=$CLJS.R.R($CLJS.Tm,$CLJS.Mg(e),b,c,d);return $CLJS.U.j(a,$CLJS.hZ,c)};$CLJS.X0=function(a,b){return S0($CLJS.r0(a),b)};$CLJS.Y0=function(a,b){return T0($CLJS.r0(a),b)};$CLJS.$0=function(a,b,c){return $CLJS.Z0.j(a,b,c)};
l1=function(a,b,c){var d=$CLJS.$0(a,b,c);return $CLJS.jn.l($CLJS.G([$CLJS.li(d,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,$CLJS.TI,$CLJS.Nl],null)),function(){var e=$CLJS.a1.D(a,b,c,$CLJS.b1);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.c1,e],null):null}(),function(){var e=$CLJS.Qm.h($CLJS.nk,$CLJS.DG)(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.nk,e],null):null}(),function(){var e=$CLJS.k0.g(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.l0,$CLJS.d1.j(a,b,$CLJS.Ae(e)?$CLJS.X0(a,e):"string"===typeof e?
$CLJS.Y0(a,$CLJS.f0(e)):null)],null):null}(),function(){var e=$CLJS.g0.g(d);return $CLJS.m(e)?new $CLJS.k(null,4,[e1,$CLJS.F.h(e,$CLJS.u0),$CLJS.f1,$CLJS.F.h(e,$CLJS.z0),g1,$CLJS.F.h(e,$CLJS.x0),$CLJS.h1,$CLJS.F.h(e,$CLJS.y0)],null):null}(),function(){var e=$CLJS.B0.g(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.i1,e],null):null}(),$CLJS.li(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.j1,$CLJS.k1],null))]))};$CLJS.m1=new $CLJS.N(null,"direction","direction",-633359395);
$CLJS.h1=new $CLJS.N(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.j1=new $CLJS.N(null,"breakout-position","breakout-position",-760153191);$CLJS.n1=new $CLJS.N(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.i1=new $CLJS.N(null,"selected","selected",574897764);Yoa=new $CLJS.N(null,"is-order-by-column","is-order-by-column",1320802549);
Zoa=new $CLJS.w("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.o1=new $CLJS.N(null,"stage-number","stage-number",-1752729638);p1=new $CLJS.N("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.q1=new $CLJS.N(null,"include-joined?","include-joined?",-410632824);e1=new $CLJS.N(null,"is-from-previous-stage","is-from-previous-stage",956780376);
r1=new $CLJS.N(null,"is-source-table","is-source-table",1559075056);$CLJS.s1=new $CLJS.N(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.f1=new $CLJS.N(null,"is-from-join","is-from-join",-1107917905);$CLJS.t1=new $CLJS.N(null,"requires-column","requires-column",934105295);$CLJS.k1=new $CLJS.N(null,"order-by-position","order-by-position",-1307229997);$oa=new $CLJS.N(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.c1=new $CLJS.N(null,"long-display-name","long-display-name",1550784464);
$CLJS.b1=new $CLJS.N(null,"long","long",-171452093);$CLJS.u1=new $CLJS.N(null,"short-name","short-name",-1767085022);$CLJS.v1=new $CLJS.N(null,"include-expressions?","include-expressions?",-1817639288);g1=new $CLJS.N(null,"is-calculated","is-calculated",-86687269);$CLJS.w1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Sk,$CLJS.DH],null),$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","display-name-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.x1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Sk,$CLJS.DH],null),$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","column-name-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.a1=function a1(a){switch(arguments.length){case 2:return a1.h(arguments[0],arguments[1]);case 3:return a1.j(arguments[0],arguments[1],arguments[2]);case 4:return a1.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.metadata.calculation.display_name",$CLJS.a1);$CLJS.a1.h=function(a,b){return $CLJS.a1.j(a,-1,b)};$CLJS.a1.j=function(a,b,c){return $CLJS.a1.D(a,b,c,$CLJS.Pj)};
$CLJS.a1.D=function(a,b,c,d){var e=$CLJS.TI.g($CLJS.EH(c));if($CLJS.m(e))return e;try{return $CLJS.w1.D(a,b,c,d)}catch(f){if(f instanceof Error)throw b=f,$CLJS.yj($CLJS.UH("Error calculating display name for {0}: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),$CLJS.SZ(b)])),new $CLJS.k(null,2,[$CLJS.uO,a,$CLJS.JG,c],null),b);throw f;}};$CLJS.a1.v=4;
$CLJS.y1=function y1(a){switch(arguments.length){case 2:return y1.h(arguments[0],arguments[1]);case 3:return y1.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.y1.h=function(a,b){return $CLJS.y1.j(a,-1,b)};
$CLJS.y1.j=function(a,b,c){var d=$CLJS.V.g($CLJS.EH(c));if($CLJS.m(d))return d;try{return $CLJS.x1.j(a,b,c)}catch(e){if(e instanceof Error)throw d=e,$CLJS.yj($CLJS.UH("Error calculating column name for {0}: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),$CLJS.SZ(d)])),new $CLJS.k(null,3,[$CLJS.JG,c,$CLJS.uO,a,$CLJS.o1,b],null),d);throw e;}};$CLJS.y1.v=3;
$CLJS.w1.o(null,$CLJS.Pj,function(a,b,c){a=$CLJS.YF($CLJS.YD);$CLJS.m($CLJS.eG("metabase.lib.metadata.calculation",a))&&$CLJS.fG("metabase.lib.metadata.calculation",a,$CLJS.Y_("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),Zoa,$CLJS.CH(c)])),null);return $CLJS.qe(c)&&$CLJS.C(c)instanceof $CLJS.N?$CLJS.ui($CLJS.C(c)):$CLJS.aj.l($CLJS.G([c]))});$CLJS.x1.o(null,$CLJS.Pj,function(a,b,c){return Xoa($CLJS.Ou($CLJS.a1.j(a,b,c),/[\(\)]/,""))});
$CLJS.z1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Sk,$CLJS.DH],null),$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,g,l){return l},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.A1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Sk,$CLJS.DH],null),$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","type-of-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.B1=function B1(a){switch(arguments.length){case 2:return B1.h(arguments[0],arguments[1]);case 3:return B1.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.B1.h=function(a,b){return $CLJS.B1.j(a,-1,b)};
$CLJS.B1.j=function(a,b,c){var d=$CLJS.EH(c);d=$CLJS.Qf(d);var e=$CLJS.M.h(d,$CLJS.PQ),f=$CLJS.nk.g(d);if($CLJS.m(f))return f;e=$CLJS.m($CLJS.m(e)?$CLJS.Ie($CLJS.VV,e):e)?$CLJS.kl:null;if($CLJS.m(e))return e;d=$CLJS.DG.g(d);if($CLJS.m(d))return d;a=$CLJS.A1.j(a,b,c);return $CLJS.OH(a,$CLJS.pl)?a:$CLJS.pl};$CLJS.B1.v=3;$CLJS.A1.o(null,$CLJS.Pj,function(a,b,c){return $CLJS.BL(c)});
$CLJS.A1.o(null,$CLJS.NL,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.B1.j(a,b,c)});$CLJS.A1.o(null,$CLJS.HX,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);return"string"===typeof d?$CLJS.BL(c):$CLJS.B1.j(a,b,d)});
$CLJS.Z0=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Sk,$CLJS.DH],null),$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","metadata-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.Z0.o(null,$CLJS.Pj,function(a,b,c){try{return new $CLJS.k(null,4,[$CLJS.tl,$CLJS.hW,$CLJS.DG,$CLJS.B1.j(a,b,c),$CLJS.V,$CLJS.y1.j(a,b,c),$CLJS.TI,$CLJS.a1.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.yj($CLJS.UH("Error calculating metadata {0}",$CLJS.G([$CLJS.SZ(d)])),new $CLJS.k(null,3,[$CLJS.uO,a,$CLJS.o1,b,$CLJS.JG,c],null),d);}throw e;}});
$CLJS.C1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Sk,$CLJS.DH],null),$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","display-info-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.jL(p1,new $CLJS.S(null,19,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.TI,$CLJS.Rl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.c1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Rl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.l0,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,p1],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[e1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,$CLJS.mk],
null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.f1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,$CLJS.mk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[g1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,$CLJS.mk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.h1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,$CLJS.mk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[r1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.Mt,$CLJS.mk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$oa,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,$CLJS.mk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Yoa,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,$CLJS.mk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Rl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pW,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Rl],null),
new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Rl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.u1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Rl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.t1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.mk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.i1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.mk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Pj,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.mk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.m1,
new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,$CLJS.FJ,$CLJS.sJ],null)],null)],null));$CLJS.d1=function d1(a){switch(arguments.length){case 2:return d1.h(arguments[0],arguments[1]);case 3:return d1.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.d1.h=function(a,b){return $CLJS.d1.j(a,-1,b)};
$CLJS.d1.j=function(a,b,c){try{return $CLJS.C1.j(a,b,c)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.yj($CLJS.UH("Error calculating display info for {0}: {1}",$CLJS.G([$CLJS.CH(c),$CLJS.SZ(d)])),new $CLJS.k(null,3,[$CLJS.uO,a,$CLJS.o1,b,$CLJS.JG,c],null),d);}throw e;}};$CLJS.d1.v=3;$CLJS.C1.o(null,$CLJS.Pj,function(a,b,c){return l1(a,b,c)});
$CLJS.C1.o(null,$CLJS.i0,function(a,b,c){return $CLJS.jn.l($CLJS.G([l1(a,b,c),new $CLJS.k(null,1,[r1,$CLJS.F.h($CLJS.YP.g($CLJS.C($CLJS.hZ.g(a))),$CLJS.Ik.g(c))],null)]))});
$CLJS.D1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Sk,$CLJS.DH],null),$CLJS.Sk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","visible-columns-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();$CLJS.D1.o(null,$CLJS.GG,function(){return $CLJS.Lg});module.exports={display_name:$CLJS.a1};